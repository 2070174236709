import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

// icons
import { GrLinkedin } from "react-icons/gr"
import { GrFacebook } from "react-icons/gr"
import { GrInstagram } from "react-icons/gr"
import { FiMail } from "react-icons/fi"
import { FiPhoneCall } from "react-icons/fi"

const Footer = ({ siteTitle }) => {
  return (
    <footer className="h-48 w-full bg-gray-900 z-10000">
      <div className="h-full mx-auto px-5 pt-4 container flex flex-wrap-reverse justify-between items-center text-white sm:flex-col-reverse xs:flex-col-reverse sm:text-center xs:text-center">
        <div>
          <h2 className="w-full sbfont">
            <Link to="/">{siteTitle}</Link>
          </h2>
          <p className="w-full ">A Building's Shield Made With Art.</p>
        </div>
        <div className="w-64 mt-6 flex justify-between font-medium text-xl  ">
          <a
            className="hover:text-gray-500 duration-200 cursor-pointer"
            href="https://www.facebook.com/Alphacladding-LLC-356008848276444"
            target="_blank"
            rel="noopener noreferrer"
          >
            <GrFacebook />
          </a>
          <a
            className="hover:text-gray-500 duration-200 cursor-pointer"
            href="https://www.linkedin.com/company/alphacladding-llc-/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <GrLinkedin />
          </a>
          <a
            className="hover:text-gray-500 duration-200 cursor-pointer"
            href="https://www.instagram.com/alphacladdingllc/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <GrInstagram />
          </a>
          <a
            className="hover:text-gray-500 duration-200 cursor-pointer"
            href="tel:+17866154245"
          >
            <FiPhoneCall />
          </a>
          <a
            className="hover:text-gray-500 duration-200 cursor-pointer"
            href="mailto:conference@alphacladding.com"
          >
            <FiMail />
          </a>
        </div>
      </div>
    </footer>
  )
}

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: ``,
}

export default Footer
