import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useState } from "react"
import { StaticQuery, graphql } from "gatsby"

// component
import Img from "gatsby-image"
import MobileHeader from "./mobileHeader"

// icons
import { GiHamburgerMenu } from "react-icons/gi"
import { AiOutlineClose } from "react-icons/ai"

const Header = ({ siteTitle }) => {
  const [menuShow, setMenuShow] = useState(false)

  return (
    <StaticQuery
      query={graphql`
        query {
          allFile(filter: { relativePath: { regex: "/images/logo/" } }) {
            edges {
              node {
                childImageSharp {
                  fluid(maxWidth: 350, quality: 100) {
                    ...GatsbyImageSharpFluid
                    ...GatsbyImageSharpFluidLimitPresentationSize
                  }
                }
              }
            }
          }
        }
      `}
      render={data => (
        <>
          <header className="fixed w-full xl:bg-gray-800 lg:bg-gray-800 bg-gray-900 z-10001 shadow-lg text-white">
            <div className="mx-auto px-5 container py-2 flex justify-between items-center">
              <Link to="/">
                <div className="flex items-center justify-between">
                  <div className="w-12 p-2 flex justify-center items-center rounded-full bg-gray-800 lg:bg-gray-900 xl:bg-gray-900">
                    <Img
                      className="w-full"
                      fluid={data.allFile.edges[0].node.childImageSharp.fluid}
                    />
                  </div>
                  <h3 className="m-0 ml-1 text-base md:hidden sm:hidden xs:hidden sbfont">
                    Alpha
                    <br />
                    cladding
                  </h3>
                </div>
              </Link>
              <div className="w-1/2 flex justify-between md:hidden sm:hidden xs:hidden  font-medium text-sm">
                <Link to="/about" className="hover:text-gray-600 duration-200">
                  About
                </Link>
                <Link
                  to="/process"
                  className="hover:text-gray-600 duration-200"
                >
                  Process
                </Link>
                <Link
                  to="/projects"
                  className="hover:text-gray-600 duration-200"
                >
                  Projects
                </Link>
                <Link to="/news" className="hover:text-gray-600 duration-200">
                  News
                </Link>
                <Link
                  to="/about#founders"
                  className="hover:text-gray-600 duration-200"
                >
                  Founders
                </Link>
              </div>
              <Link
                to="/#contact"
                className="bg-green-700 px-3 py-1 rounded-full text-white md:hidden sm:hidden xs:hidden duration-200 hover:bg-green-900 font-medium text-sm"
              >
                Contact
              </Link>
              <span
                role="button"
                tabIndex={0}
                className="lg:hidden xl:hidden text-2xl cursor-pointer font-bold"
                aria-label="button to toggle menu"
                onClick={() => setMenuShow(!menuShow)}
                onKeyDown={() => setMenuShow(!menuShow)}
              >
                {!menuShow ? <GiHamburgerMenu /> : <AiOutlineClose />}
              </span>
            </div>
          </header>

          <MobileHeader
            setMenuShow={setMenuShow}
            menuShow={menuShow}
            container={`fixed w-full ease-in duration-300 lg:hidden xl:hidden px-5 z-10000
        font-bold ${menuShow ? "mt-24" : "-mt-96"}`}
            subcontainer="container mx-auto w-full text-center flex bg-gray-900 text-white shadow-lg overflow-hidden"
            link="pt-5 pb-1 border-solid border-b border-gray-700 hover:bg-gray-700 duration-200"
            iconlink="w-full flex justify-center py-2 hover:text-gray-300 duration-200 text-white text-2xl"
          />

          <div
            className={`w-full h-screen fixed z-10 opacity-0 bg-black duration-300 lg:hidden xl:hidden ${
              menuShow ? "opacity-50 z-999" : " hidden "
            }`}
            aria-label="button to close menu"
            role="button"
            tabIndex={0}
            onClick={() => setMenuShow(false)}
            onKeyDown={() => setMenuShow(false)}
          />
        </>
      )}
    />
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
