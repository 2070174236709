import React from "react"
import { Link } from "gatsby"

// icons
import { GrLinkedin } from "react-icons/gr"
import { GrFacebook } from "react-icons/gr"
import { GrInstagram } from "react-icons/gr"

export default function MobileHeader({
  container,
  subcontainer,
  link,
  iconlink,
  menuShow,
  setMenuShow,
}) {
  return (
    <div
      className={container}
      aria-label="button to close menu on link press"
      role="button"
      tabIndex={0}
      onKeyDown={() => setMenuShow(!menuShow)}
      onClick={() => setMenuShow(!menuShow)}
    >
      <div className={subcontainer + " flex-col"}>
        <Link to="/about" className={link}>
          About
        </Link>
        <Link to="/process" className={link}>
          Process
        </Link>
        <Link to="/projects" className={link}>
          Projects
        </Link>
        <Link to="/news" className={link}>
          News
        </Link>
        <Link to="/about#founders" className={link}>
          Founders
        </Link>
        <Link to="/#contact" className={link + " pb-1 border-none"}>
          Contact
        </Link>
      </div>
      <div className={subcontainer + " cursor-pointer text-lg"}>
        <a
          className={iconlink + " bg-purple-700 hover:bg-purple-900"}
          href="https://www.linkedin.com/company/alphacladding-llc-/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <GrLinkedin />
        </a>
        <a
          className={iconlink + " bg-green-600 hover:bg-green-800"}
          href="https://www.instagram.com/alphacladdingllc/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <GrInstagram />
        </a>
        <a
          className={iconlink + " bg-blue-600 hover:bg-blue-800"}
          href="https://www.facebook.com/Alphacladding-LLC-356008848276444"
          target="_blank"
          rel="noopener noreferrer"
        >
          <GrFacebook />
        </a>
      </div>
    </div>
  )
}
